function getDisplay(node, target) {
  if (node.name === target) {
    return node.display || node.name;
  }

  if (node.children) {
    for (let subtreeNode of node.children) {
      let display = getDisplay(subtreeNode, target);
      if (display) {
        return display;
      }
    }
  }

  return ""; // target not in tree
}

export { getDisplay };
