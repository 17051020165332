const tree = [
  {
    name: "root",
    display: "All",
    children: [
      {
        name: "A",
        display: "Asia Articles",
        children: [
          {
            name: "A-1",
            display: "Japan",
            children: [
              {
                name: "A-1-1",
                display: "Tokyo",
                children: []
              }
            ]
          },
          {
            name: "A-2",
            display: "Taiwan Articles",
            children: [
              {
                name: "A-2-1",
                display: "Taipei",
                children: []
              },
              {
                name: "A-2-2",
                display: "Keelung",
                children: []
              }
            ]
          }
        ]
      },
      {
        name: "B",
        display: "Europe",
        children: [
          {
            name: "B-1",
            display: "Italy",
            children: [
              {
                name: "B-1-1",
                children: []
              },
              {
                name: "B-1-2",
                children: []
              }
            ]
          },
          {
            name: "B-2",
            display: "France",
            children: [
              {
                name: "B-2-1",
                children: []
              },
              {
                name: "B-2-2",
                children: []
              }
            ]
          }
        ]
      },
      {
        name: "C",
        display: "Africa",
        children: []
      }
    ]
  }
];

module.exports = tree;
exports.default = tree;
